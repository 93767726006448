/**
 * Jquery extensions by Dropsolid
 */

(function($,window,document){
  "use strict";

  /**
   * Functie om de breakpoint te bepalen aan de hand van de content van het after element op de body.
   * Functie op te roepen met $.getBreakpoint().
   */
  $.getBreakpoint = function(){
    var tag = window.getComputedStyle(document.body, ':after').getPropertyValue('content');
    // Firefox bugfix
    tag = tag.replace(/"/g,'');
    return tag.replace(/'/g,'');
  };

  //__________________________________________________________________________________________________________________

  /**
   * Functie om geanimeerd te scrollen naar een anchor in de pagina.
   * Functie op te roepen met $.scrollto(target,offset).
   *
   * @target = required!, jquery object, element waarheen gescrolled moet worden
   * @offset = niet required, numeriek, zet deze indien er een stickie menu of header bovenaan de pagina staat
   * @cb = callback function that can be invoked after scrollto is done
   */
  $.scrollto = function(target, offset, cb){
    console.log('here');
    if (typeof offset == 'undefined') {
      offset = 0;
    }
    $('html,body').stop().animate({
      scrollTop: target.offset().top - offset
    }, 1000, function(){
      if (typeof cb !== 'undefined') {
        cb();
      }
    });
    return false;
  };

  $.heightsEqualizer = function(selector) {
    var elements = document.querySelectorAll(selector),
        max_height = 0,
        len = 0,
        i;

    if ( (elements) && (elements.length > 0) ) {
      len = elements.length;

      for (i = 0; i < len; i++) { // get max height
        elements[i].style.height = ''; // reset height attr
        if (elements[i].clientHeight > max_height) {
          max_height = elements[i].clientHeight;
        }
      }

      for (i = 0; i < len; i++) { // set max height to all elements
        elements[i].style.height = max_height + 'px';
      }
    }
  }
  $.removeHeightsEqualizer = function(selector) {
    var elements = document.querySelectorAll(selector),
        len = 0,
        i;
    if ( (elements) && (elements.length > 0) ) {
      len = elements.length;

      for (i = 0; i < len; i++) { // get max height
        elements[i].style.height = ''; // reset height attr
      }
    }
  }

})(jQuery, window, document);

// Add ScrollMagic controller for onEnter (add script via cdn in template.php)
var _dropsolid_base_scrollmagic_controller = new ScrollMagic.Controller();

(function ($) {
  Drupal.behaviors.dropsolid_base = {
    attach: function (context, settings) {

      // Set current breakpoint
      var currentBreakpoint = $.getBreakpoint();

      //Once needed to avoid multiple clicks when extra content is added to the page. (even vimeo vid)
      // $('.menu h2', context).once('h2').click(function () {
      //   $(this).parents('.menu').toggleClass('open');
      // });
      // $('.menu a.active', context).once('a.active').click(function (event) {
      //   event.preventDefault();
      //   $(this).parents('.menu').toggleClass('open');
      // });

      // responsive menu
      $('.hamburger').on('click', function(e){
        $('.page-wrapper header[role="banner"]').toggleClass('open');
      });

      // Click away Drupal messages
      $('.messages .close').click(function (event) {
        event.preventDefault();
        $(this).parents('.messages').addClass('closing');
      });
      $('.messages').bind("transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd", function () {
        if ($(this).hasClass('closing')) {
          $(this).addClass('closed');
        }
      });

      // Scrollto
      $('a[href*=#]:not([href=#])').click(function (event) {
        console.log('scroolto');
        var path = this.href;
        var Current = window.location.pathname;
        var id = '';

        // if link is on current page, prevent reload.
        if (path.indexOf(Current) >= 0 && Current != '/') {
          // get ID/hash from url
          id = event.target.href.substring(event.target.href.indexOf("#")+1);
        }
        else if(Current == '/') {
          var Hash = event.target.href.substring(event.target.href.indexOf("#")+1);
          if($('#'+Hash).length) {
            id = Hash;
          }
        }
        if(id != '') {
          if($('#' + id).length != 0) {
            event.preventDefault();
            $.scrollto($('#' + id), 92);
          }
        }
      });

      // sticky header
      $('body').once('sticky', function() {
        var sticky_header = new ScrollMagic.Scene({
          triggerElement: 'header[role=banner]',
          triggerHook: 0,
          reverse: true
        })
        .setPin('header[role=banner]')
        .addTo(_dropsolid_base_scrollmagic_controller);

        sticky_header.refresh();
        window.addEventListener('orientationchange', updateOrientation, false);

        function updateOrientation() {
          // trigger scrollmagic
          _dropsolid_base_scrollmagic_controller.update();
        }
      });

      // Add responsive functionality to tables added via WYSIWYG
      $('table').each(function() {
        if(!$(this).hasClass('responsive')) {
          if($(this).find('thead').length) {
            var headings = [];
            $(this).find('th').each(function(){
              headings.push($(this).text());
            });
            var count = 0;
            $(this).find('tr').each(function(){
              $(this).find('td').attr('data-title', headings[count-1]);
              ++count;
            });
          } else {
            $(this).find('tr').each(function(){
              var heading = $(this).find('th').text();
              $(this).find('td').each(function(){
                $(this).attr('data-title', heading);
              });
            });
          }
          $(this).addClass('responsive');
        }
      });

      $( document ).ready(function() {
        $.heightsEqualizer('.owl-item .field-name-field-testimonial-text');
      });

      // homepage
      if ($('body').hasClass('front')) {
        if (currentBreakpoint == 'breakpoint2') {
          $(window).load(function() {
            equalheigtsBlocks();
          });
        }
        $(window).resize(function(){
          console.log('rezize');
          if ($.getBreakpoint() == 'breakpoint2') {
            equalheigtsBlocks();
          }
          else {
            removeEqualheigtsBlock();
          }
        });

        function equalheigtsBlocks() {
          $.heightsEqualizer('.intro-eh');
          //$.heightsEqualizer('.izw-eh');
          $.heightsEqualizer('.izw-eh-list');
          $.heightsEqualizer('.vacancies-eh');
        }
        function removeEqualheigtsBlock() {
          $.removeHeightsEqualizer('.intro-eh');
          $.removeHeightsEqualizer('.izw-eh-list');
          $.removeHeightsEqualizer('.vacancies-eh');
        }
      }
    }
  };

})(jQuery);
